import axios from 'axios'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import Logger from '../../helpers/Logger'
import Utils from '../../helpers/Utils'
import Course from '../../models/Course'
import Lesson from '../../models/Lesson'
import FoldableList from './FoldableList'
import Theme from '../../models/Theme'
import Material from '../../models/Material'
import ListItem from './ListItem'
import PreloaderProgressBar from "./PreloaderProgressBar";

class CourseTree extends React.Component {
	
	constructor(props) {
		
		super(props)
		
		this.state = {
			
			lessons: [],
			
			lessonsAreLoading: 0,
			themesAreLoading: 0,
			materialsAreLoading: 0,
			
			useDebugInfo: 0,
			
		}
		
	}
	
	loadLessons = () => {
		
		const logName = 'CourseTree.loadLessons'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		const course = this.props.course
		Logger.log(course, 'course', logAllow)
		
		if (!course) {
			return
		}
		
		this.props.preloader?.show()
		
		this.setState({
			lessonsAreLoading: 1,
		})
		
		axios({
			method: 'get',
			url: Utils.apiUrl('lessons/list'),
			params: {
				accessToken: Utils.getUserToken(),
				courseId: course.id,
				isActive: 1,
				progressForUserId: this.props.progressUserId,
			},
			data: {},
		}).then((response) => {
			
			const logName = 'CourseTree.loadLessons.ajax.done'
			const logAllow = 1
			const logCollapsed = 0
			
			Logger.groupStart(logName, logAllow, logCollapsed)
			
			let lessons = response.data.map(x => new Lesson(x))
			Logger.log(lessons, 'lessons', logAllow)
			
			this.props.preloader?.hide()
			
			this.setState({
				lessons: lessons,
				lessonsAreLoading: 0,
			})
			
			Logger.groupEnd(logAllow)
			
		}).catch((error) => {
			
			console.log(error)
			window.alert(error)
			
		})
		
		Logger.groupEnd(logAllow)
		
	}
	
	loadThemes = (lessonId) => {
		
		const logName = 'CourseTree.loadThemes'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		if (!lessonId) {
			return
		}
		
		this.setState({
			themesAreLoading: 1,
		})
		
		this.props.preloader?.show()
		
		let params = {
			accessToken: Utils.getUserToken(),
			'filter[lesson_id]': lessonId,
			'filter[is_active]': 1,
			...this.props.themesLoadParams,
		}
		
		Logger.log(params, 'params', logAllow)
		
		Logger.groupEnd(logAllow)
		
		axios({
			method: 'get',
			url: Utils.apiUrl('themes'),
			params: params,
			data: {},
		}).then((response) => {
			
			const logName = 'CourseTree.loadThemes.ajax.done'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			this.props.preloader?.hide()
			
			Logger.log(response, 'response', logAllow)
			
			let themes = response.data.map(x => new Theme(x))
			Logger.log(themes, 'themes', logAllow)
			
			let lessons = this.state.lessons
			
			let lessonIndex = lessons.findIndex(lesson => lesson.id == lessonId)
			
			if (lessonIndex < 0) {
				throw new Error(`lesson #${lessonId} not found in state`)
			}
			
			let lesson = lessons[lessonIndex]
			
			lesson.themes = themes
			
			lessons[lessonIndex] = lesson
			
			this.setState({
				lessons: lessons,
				themesAreLoading: 0,
			})
			
			Logger.groupEnd(logAllow)
			
		}).catch((axiosError) => {
			console.log(`axiosError = %o`, axiosError)
			window.alert(axiosError)
		})
		
	}
	
	loadMaterials = (themeId, lessonId) => {
		
		if (!themeId) {
			return
		}
		
		this.setState({
			materialsAreLoading: 1,
		})
		
		this.props.preloader?.show()
		
		axios({
			method: 'get',
			url: Utils.apiUrl('materials/list'),
			params: {
				accessToken: Utils.getUserToken(),
				// 'filter[theme_id]': themeId,
				themeId: themeId,
				// withQuestionsOnly: 1,
			},
			data: {},
		}).then((response) => {
			
			const logName = 'CourseTree.loadMaterials.ajax.done'
			const logAllow = 1
			const logCollapse = 0
			
			Logger.groupStart(logName, logAllow, logCollapse)
			
			this.props.preloader?.hide()
			
			let materials = response.data.map(x => new Material(x))
			Logger.log(materials, 'materials', logAllow)
			
			let lessons = this.state.lessons
			let lessonIndex = lessons.findIndex(lesson => lesson.id == lessonId)
			let lesson = lessons[lessonIndex]
			
			if (!lesson) {
				throw new Error(`lesson #${lessonId} not found in state`)
			}
			
			let themes = lesson.themes
			let themeIndex = themes.findIndex(theme => theme.id == themeId)
			let theme = themes[themeIndex]
			
			if (!theme) {
				throw new Error(`theme #${themeId} not found in state (lesson #${lessonId})`)
			}
			
			theme.materials = materials
			
			lesson[themeId] = theme
			lessons[lessonId] = lesson
			
			this.setState({
				lessons: lessons,
				materialsAreLoading: 0,
			})
			
			Logger.groupEnd(logAllow)
			
		}).catch((axiosError) => {
			console.log(`axiosError = %o`, axiosError)
			window.alert(axiosError)
		})
		
	}
	
	componentDidMount() {
		
		if (!this.props.folded) {
			this.loadLessons()
		}
		
	}
	
	render() {
		
		const logName = 'CourseTree.render'
		const logAllow = 0
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		const course = this.props.course
		const lessons = this.state.lessons
		
		let useMaterials = this.props.useMaterials
		let useDebugText = this.state.useDebugInfo
		
		Logger.groupEnd(logAllow)
		
		return (
			
			<div className={'CourseTree'}>
				
				{(course) ? (
					
					<FoldableList
						folded={this.props.folded}
						name={course.name}
						upName={i18next.t('Course')}
						debugText={useDebugText ? [
							'#' + course.id,
							'created at: ' + course.created_at,
							'is_active: ' + course.is_active,
						].join(' | ') : null}
						onUnfold={async () => {
							
							if (lessons.length < 1) {
								await this.loadLessons()
							}
							
							this.props.onCourseUnfold()
							this.props.onCourseFoldToggle()
							
						}}
						extraClassName={this.props.courseExtraClassName}
					>
						
						{(this.props.courseHead) && (
							<div className={`head mb-3`}>
								{this.props.courseHead}
							</div>
						)}
						
						{(lessons.length > 0) ? (
							
							<>
								
								{lessons.map((lesson) => {
									
									// LESSONS
									
									let themes = lesson.themes
									
									return (
										
										<FoldableList
											key={`lesson-${lesson.id}}`}
											name={lesson.name}
											upName={i18next.t('Lesson')}
											onUnfold={() => {
												
												if (themes.length < 1) {
													this.loadThemes(lesson.id)
												}
												
												this.props.onLessonUnfold()
												this.props.onLessonFoldToggle()
												
											}}
											debugText={useDebugText ? [
												'#' + lesson.id,
												'created at: ' + lesson.created_at,
												'is_active: ' + lesson.is_active,
											].join(' | ') : null}
										>
											
											{(this.props.lessonHeadMaker) && (
												
												<div className={`lesson-head`}>
													
													{this.props.lessonHeadMaker(lesson, course)}
												
												</div>
											
											)}
											
											{(themes.length > 0) ? (
												
												<>
													
													{themes.map((theme) => {
														
														// THEMES
														
														let materials = theme.materials
														
														let themeExtraClassName = this.props.themeExtraClassNameMaker
															? this.props.themeExtraClassNameMaker(theme, lesson, course)
															: []
														
														return (
															
															<FoldableList
																key={`theme-${theme.id}}`}
																name={theme.name}
																upName={i18next.t('Theme')}
																onToggle={() => {
																	this.props.onThemeFoldToggle(theme, lesson)
																}}
																onUnfold={() => {
																	if (useMaterials && materials.length < 1) {
																		this.loadMaterials(theme.id, lesson.id)
																	}
																}}
																folded={true}
																debugText={useDebugText ? [
																	'#' + theme.id,
																	'created at: ' + theme.created_at,
																	'is_active: ' + theme.is_active,
																].join(' | ') : null}
																extraClassName={themeExtraClassName}
																noContent={!useMaterials && !this.props.themeHeadMaker}
															>
																
																{(this.props.themeHeadMaker) && (
																	
																	<div className={`theme-head`}>
																		
																		{this.props.themeHeadMaker(theme, lesson, course)}
																	
																	</div>
																
																)}
																
																{(useMaterials) && (
																	
																	<>
																		
																		{(materials.length > 0) ? (
																			
																			<>
																				
																				{materials.map((material) => {
																					
																					// MATERIALS
																					
																					let classNameArray = []
																					
																					// let isPicked = 
																					
																					return (
																						<ListItem
																							key={`material-${material.id}}`}
																							title={material.name}
																							active={1}
																						>
																							<div className={`material-name`}>
																								{material.name}
																							</div>
																							<div className={`material-info small text-muted`}>
																								{material.questions.length || material.questions_count || 0} {i18next.t("questions")}
																							</div>
																						</ListItem>
																					)
																					
																				})}
																			
																			</>
																		
																		) : (
																			
																			<>
																				
																				{(this.state.materialsAreLoading) ? (
																					
																					<>
																						{/*{i18next.t('Loading')}...*/}
																						<PreloaderProgressBar/>
																					</>
																				
																				) : (
																					
																					<>{i18next.t('Materials not found')}</>
																				
																				)}
																			
																			</>
																		
																		)}
																	
																	</>
																
																)}
															
															</FoldableList>
														
														)
														
													})}
												
												</>
											
											) : (
												
												<>
													{(this.state.themesAreLoading) ? (
														<>
															{/*{i18next.t("Loading")}...*/}
															<PreloaderProgressBar/>
														</>
													) : (
														<>
															{this.props.themesNotFoundMsgMaker(lesson, course)}
														</>
													)}
												</>
											
											)}
										
										</FoldableList>
									
									)
									
								})}
								
							</>
							
						) : (
							
							<>
								{(this.state.lessonsAreLoading) ? (
									
									<>
										
										{/*{i18next.t("Loading")}...*/}
										
										<PreloaderProgressBar/>
										
									</>
									
								) : (
									
									<>
										
										{i18next.t("Lessons not found")}
										
									</>
									
								)}
							</>
						
						)}
					
					</FoldableList>
				
				) : (
					
					<div className={`alert alert-danger`}>
						{i18next.t("Course not found")}
					</div>
				
				)}
			
			</div>
		
		)
		
	}
	
}

CourseTree.propTypes = {
	
	preloader: PropTypes.object,
	
	course: PropTypes.instanceOf(Course).isRequired,
	
	folded: PropTypes.bool,
	
	progressUserId: PropTypes.any,
	
	onCourseFold: PropTypes.func,
	onCourseUnfold: PropTypes.func,
	onCourseFoldToggle: PropTypes.func,
	
	onThemeFold: PropTypes.func,
	onThemeUnfold: PropTypes.func,
	onThemeFoldToggle: PropTypes.func,
	
	onLessonFold: PropTypes.func,
	onLessonUnfold: PropTypes.func,
	onLessonFoldToggle: PropTypes.func,
	
	courseHead: PropTypes.any,
	courseExtraClassName: PropTypes.array,
	
	lessonHeadMaker: PropTypes.func, // (lesson, course) => {...}
	lessonExtraClassNameMaker: PropTypes.func, // (lesson) => {...}
	
	themesNotFoundMsgMaker: PropTypes.func, // (lesson, course) => {...}
	themesLoadParams: PropTypes.object, // {}
	themeHeadMaker: PropTypes.func, // (theme, lesson, course) => {...}
	themeExtraClassNameMaker: PropTypes.func, // (theme, lesson, course) => {...}
	
	materialOnClick: PropTypes.func,
	
	useMaterials: PropTypes.any,
	
}

CourseTree.defaultProps = {
	
	folded: true,
	
	useMaterials: true,
	
	onCourseFold: () => {},
	onCourseUnfold: () => {},
	onCourseFoldToggle: () => {},
	
	onThemeFold: () => {},
	onThemeUnfold: () => {},
	onThemeFoldToggle: () => {},
	
	onLessonFold: () => {},
	onLessonUnfold: () => {},
	onLessonFoldToggle: () => {},
	
	loadLessonsParams: {},
	loadThemesParams: {},
	
	courseExtraClassName: [],
	
	themesNotFoundMsgMaker: (lesson, course) => {
		return i18next.t("Themes not found")
	},
	
	themeExtraClassNameMaker: () => {},
	
}

export default CourseTree