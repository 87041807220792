import React from 'react';
import PropTypes from 'prop-types';
import Logger from "../../helpers/Logger";
import Config from "../../helpers/Config";
import Utils from "../../helpers/Utils";
import axios from "axios";
import i18next from "i18next";
import FormDataHelper from "../../helpers/FormDataHelper";
import MaterialGroupBind from "../../models/MaterialGroupBind";
import DateHelper from "../../helpers/DateHelper";
import ModelsPage from "./ModelsPage";

export default class MaterialAccessForm extends React.Component {
	
	modeCreate = 1;
	modeUpdate = 0;
	
	allowMultipleGroups = 0;
	
	constructor(props) {
		
		const logName = 'MaterialGroupBindForm.constructor';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(props, 'props', logAllow);
		
		super(props);
		
		let model = props.model ? {...props.model} : new MaterialGroupBind();
		
		if (model.id) {
			this.modeCreate = 0;
			this.modeUpdate = 1;
		}
		
		this.state = {
			model: model,
			groups_ids: [],
		};

		// model.access_start_date = DateHelper.formatDate({});
		
		if (Utils.isDev() && !model.id) {
			// fake
			// model.group_id = 111;
			model.access_start_date = DateHelper.formatDate({});
		}
		
		Logger.groupEnd(logAllow);
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const attr = target.getAttribute('name');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		});
		
	};
	
	submit = (event) => {
		
		event.preventDefault();
		event.stopPropagation();
		
		const logName = 'MaterialGroupBindForm.submit';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(event.keyCode, 'event.keyCode', logAllow);
		Logger.log(Utils.keyCodes.enter, 'Utils.keyCodes.enter', logAllow);
		
		if (event.keyCode == Utils.keyCodes.enter) {
			return false;
		}
		
		const form = event.currentTarget;
		const model = this.state.model;
		
		const groupsIds = this.state.groups_ids
		Logger.log(groupsIds, 'groupsIds', logAllow)
		
		if (!model.group_id && groupsIds.length < 1) {
			window.alert(i18next.t("Groups not selected"));
			return;
		}
		
		model.groups_ids = groupsIds;
		
		if (form.checkValidity()) {
			
			if (this.props.preloader) {
				this.props.preloader.show();
			}
			
			const formData = FormDataHelper.get(model);
			
			let url = Utils.apiUrl('material-group-bind') + (this.state.model.id ? '/' + this.state.model.id : '');
			Logger.log(url, 'url', logAllow);
			
			axios({
				method: this.modeUpdate ? 'put' : 'post',
				url: url,
				data: formData,
				params: {
					'accessToken': Utils.getUserToken(),
				},
			}).then((response) => {
				
				const logName = 'MaterialGroupBindForm.submit.ajax.done';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(response, 'response', logAllow);
				
				if (this.props.afterSuccess) {
					this.props.afterSuccess(response, model);
				}
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				Logger.groupEnd(logAllow);
				
			}).catch((error) => {
				
				const logName = 'MaterialGroupBindForm.submit.ajax.error';
				const logAllow = 1;
				const logCollapsed = 0;
				
				Logger.groupStart(logName, logAllow, logCollapsed);
				
				Logger.log(error, 'response', logAllow);
				
				if (this.props.preloader) {
					this.props.preloader.hide();
				}
				
				if (this.props.afterError) {
					this.props.afterError(error);
				}
				
				if (Utils.isDev(Config)) {
					Utils.axiosErrorAlert(error);
				}
				
				Logger.groupEnd(logAllow);
				
			});
			
		}
		
		Logger.groupEnd(logAllow);
		
	};
	
	render() {
		
		const model = this.state.model;
		
		return (
			
			<form className={'MaterialGroupBindForm'} onSubmit={this.submit}>
				
				<div className={'form-group material_id required d-none'}>
					<label htmlFor={'material_id'}>{i18next.t("Material ID")}</label>
					<input
						id={'material_id'}
						name={'material_id'}
						type={'number'}
						className={'form-control'}
						value={model.material_id}
						onChange={this.inputChange}
						placeholder={''}
						required={true}
					/>
				</div>
				
				{/*<div className={'form-group group_id required'}>
					<label htmlFor={'group_id'}>{i18next.t("Group ID")}</label>
					<input
						id={'group_id'}
						name={'group_id'}
						type={'number'}
						className={'form-control'}
						value={model.group_id}
						onChange={this.inputChange}
						placeholder={''}
						required={true}
					/>
				</div>*/}
				
				{/*<legend>{i18next.t("Group")}</legend>*/}
				
				<div className="group-selector">
					
					<ModelsPage
						loadUrl={Utils.apiUrl('groups')}
						gridViewCols={{
							// id: {
							// 	filter: 'number',
							// 	width: '200px',
							// },
							name: {
								name: i18next.t("Group"),
								filter: 'text',
								filterLike: true,
								value: (group) => {
									return (
										<>
											
											<span className={`group-name`}>
												{group.name}
											</span>
											
											<small
												className={`group-id text-muted d-inline-block ml-2`}
												title={i18next.t("Group ID")}
											>#{group.id}</small>
											
										</>
									)
								}
							},
						}}
						preloader={this.props.preloader}
						// instantFilter={true}
						rowOnClick={(group, event) => {
							
							let model = this.state.model
							
							let groupsIds = this.state.groups_ids
							
							let groupId = group.id
							
							let isSelected = model.group_id == groupId || groupsIds.includes(groupId)
							
							if (isSelected) {
								
								// deselect group
								
								model.group_id = null
								
								groupsIds.splice(groupsIds.indexOf(groupId), 1)
								
							} else {
								
								// select group
								
								model.group_id = group.id
								
								if (this.modeCreate && this.allowMultipleGroups) {
									groupsIds.push(group.id)
								}
								
							}
							
							this.setState((prevState) => {
								return {
									model: model,
									groups_ids: groupsIds,
								}
							});
							
						}}
						rowClassNameMaker={(group) => {
							
							// return group.id == this.state.model.group_id ? 'table-success' : null;
							
							let isSelectedOne = this.state.model.group_id == group.id
							let isSelectedMany = this.state.groups_ids.includes(group.id)
							
							let isSelected = this.modeCreate && this.allowMultipleGroups
								? isSelectedOne || isSelectedMany
								: isSelectedOne
							
							return isSelected ? 'table-success' : null
							
						}}
					/>
					
				</div>
				
				<hr/>
				
				<div className={'form-group access_start_date required'}>
					<label htmlFor={'access_start_date'}>{i18next.t("Start date")}</label>
					<input
						id={'access_start_date'}
						name={'access_start_date'}
						type={'date'}
						className={'form-control'}
						value={model.access_start_date}
						onChange={this.inputChange}
						placeholder={''}
						required={true}
					/>
				</div>
				
				<div className={'form-group access_start_time'}>
					<label htmlFor={'access_start_time'}>{i18next.t("Start time")}</label>
					<input
						id={'access_start_time'}
						name={'access_start_time'}
						type={'time'}
						className={'form-control'}
						value={model.access_start_time}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className={'form-group access_end_date'}>
					<label htmlFor={'access_end_date'}>{i18next.t("End date")}</label>
					<input
						id={'access_end_date'}
						name={'access_end_date'}
						type={'date'}
						className={'form-control'}
						value={model.access_end_date}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className={'form-group access_end_time'}>
					<label htmlFor={'access_end_time'}>{i18next.t("End time")}</label>
					<input
						id={'access_end_time'}
						name={'access_end_time'}
						type={'time'}
						className={'form-control'}
						value={model.access_end_time}
						onChange={this.inputChange}
						placeholder={''}
						required={false}
					/>
				</div>
				
				<div className="controls">
					
					<div className="row">
						
						<div className="col-6">
							{this.props.cancel && (
								<button
									type={'button'}
									className={'my-btn my-btn-danger'}
									onClick={(event) => {
										this.props.cancel();
									}}
								>{i18next.t("Cancel")}</button>
							)}
						</div>
						
						<div className="col-6 text-right">
							<button
								type={'submit'}
								// type={'button'}
								className={'my-btn my-btn-primary'}
							>{i18next.t("Save")}</button>
						</div>
					
					</div>
				
				</div>
			
			</form>
		
		);
		
	}
	
}

MaterialAccessForm.propTypes = {
	model: PropTypes.object,
	afterSuccess: PropTypes.func,
	afterError: PropTypes.func,
	cancel: PropTypes.func,
	preloader: PropTypes.object,
};

MaterialAccessForm.defaultProps = {
	model: null,
};