import "./StudentView.css";

import React from 'react';
import PropTypes from 'prop-types';
import i18next from "i18next";
import ListItem from "./ListItem";
import AvatarPlugImg from "../../img/avatar-plug.svg";
import User from "../../models/User";
import BackBtn from "./BackBtn";
import Logger from "../../helpers/Logger";
import axios from "axios";
import Utils from "../../helpers/Utils";
import Course from "../../models/Course";
import Lesson from "../../models/Lesson";
import Theme from "../../models/Theme";
import Material from "../../models/Material";
import Config from "../../helpers/Config";
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

export default class StudentView extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			
			unfoldedCoursesIds: [],
			unfoldedLessonsIds: [],
			unfoldedThemesIds: [],
			unfoldedMaterialsIds: [],
			
			courses: [],
			lessons: [],
			themes: [],

			progress: [],
			
			currentCourse: null,
			currentLesson: null,
			currentTheme: null,
			currentMaterial: null,
			
			questionsModalIsOpen: false,
			
		};
		
	}
	
	loadCourses = () => {
		
		const logName = 'StudentView.loadCourses';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('users/get-courses'),
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				userId: this.props.student.id,
			},
		}).then((response) => {
			
			const logName = 'StudentView.loadCourses.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			let courses = response.data.map((courseData) => {
				return new Course(courseData);
			});
			
			Logger.log(courses, 'courses', logAllow);
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			console.log('error.response = %o', error.response);
			
			if (error.response && error.response.data) {
				window.alert(error.response.data.message);
			} else {
				window.alert(error.message);
			}
			
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	loadLessons = (courseId, courseIndex) => {
		
		const logName = 'StudentView.loadLessons';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('lessons/list'),
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				courseId: courseId,
				progressForUserId: this.props.student.id,
				// 'filter[course_id]': courseId,
				// 'filter[is_active]': 1,
				'per-page': 999,
			},
		}).then((response) => {
			
			const logName = 'StudentView.loadLessons.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			let lessons = response.data.map(lessonData => new Lesson(lessonData));

			Logger.log(lessons, 'lessons', logAllow);
			
			let courses = this.state.courses;
			
			let course = courses[courseIndex];
			
			Logger.log(course, 'course', logAllow);
			
			course.lessons = lessons;
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			Logger.groupEnd(logAllow);
		
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			console.log('error.response = %o', error.response);
			
			if (error.response && error.response.data) {
				window.alert(error.response.data.message);
			} else {
				window.alert(error.message);
			}
			
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	loadThemes = (lessonId, lessonIndex, courseIndex) => {
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('themes/list'),
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				lessonId: lessonId,
				progressForUserId: this.props.student.id,
				// 'filter[lesson_id]': lessonId,
				// 'filter[is_active]': 1,
				// 'per-page': 999,
			},
		}).then((response) => {
			
			const logName = 'StudentView.loadThemes.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			let themes = response.data.map(data => new Theme(data));
			
			let courses = this.state.courses;
			
			let course = courses[courseIndex];
			
			let lessons = course.lessons;
			
			let lesson = lessons[lessonIndex];
			
			lesson.themes = themes;
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			console.log('error.response = %o', error.response);
			if (error.response && error.response.data) {
				window.alert(error.response.data.message);
			} else {
				window.alert(error.message);
			}
		});
		
	};
	
	loadMaterials = (themeId, themeIndex, lessonIndex, courseIndex) => {
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('materials/list'),
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
				themeId: themeId,
				progressForUserId: this.props.student.id,
				withQuestionsOnly: 1,
				// 'filter[is_active]': 1,
				// 'per-page': 999,
			},
		}).then((response) => {
			
			const logName = 'StudentView.loadMaterials.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			let materials = response.data.map(x => new Material(x));
			
			let courses = this.state.courses;
			
			let course = courses[courseIndex];
			
			let lesson = course.lessons[lessonIndex];
			
			let theme = lesson.themes[themeIndex];
			
			theme.materials = materials;
			
			Logger.log(theme, 'theme', logAllow);
			Logger.log(lesson, 'lesson', logAllow);
			Logger.log(course, 'course', logAllow);
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Utils.axiosErrorAlert(error);
			
		});
		
	};
	
	loadQuestions = (materialId, materialIndex, themeIndex, lessonIndex, courseIndex) => {
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'get',
			url: Utils.apiUrl('materials') + '/' + materialId,
			data: {},
			params: {
				accessToken: Utils.getUserToken(),
			},
		}).then((response) => {
			
			const logName = 'StudentView.loadQuestions.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			let materialWithQuestions = new Material(response.data);
			
			let courses = this.state.courses;
			
			let course = courses[courseIndex];
			
			let lesson = course.lessons[lessonIndex];
			
			let theme = lesson.themes[themeIndex];
			
			theme.materials[materialIndex] = materialWithQuestions;
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			console.log('error.response = %o', error.response);
			if (error.response && error.response.data) {
				window.alert(error.response.data.message);
			} else {
				window.alert(error.message);
			}
		});
		
	};
	
	unlockExam = (material, materialIndex, themeIndex, lessonIndex, courseIndex) => {
		
		const logName = 'StudentsPage2.unlockExam';
		const logAllow = 1;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		if (!window.confirm(i18next.t("Are you shure?"))) {
			return;
		}
		
		if (this.props.preloader) {
			this.props.preloader.show();
		}
		
		axios({
			method: 'put',
			url: Utils.apiUrl('materials/unlock-exam'),
			data: {
				'user_id': this.props.student.id,
				'material_id': material.id,
			},
			params: {
				'accessToken': Utils.getUserToken(),
			},
		}).then((response) => {
			
			const logName = 'StudentView.unlockBtn.ajax.done';
			const logAllow = 1;
			const logCollapsed = 0;
			
			Logger.groupStart(logName, logAllow, logCollapsed);
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Logger.log(response, 'response', logAllow);
			
			material.progress = 0;
			
			let courses = this.state.courses;
			
			let course = courses[courseIndex];
			
			let lesson = course.lessons[lessonIndex];
			
			let theme = lesson.themes[themeIndex];
			
			theme.materials[materialIndex] = material;
			
			this.setState((prevState) => {
				return {
					courses: courses,
				}
			});
			
			Logger.groupEnd(logAllow);
			
		}).catch((error) => {
			
			if (this.props.preloader) {
				this.props.preloader.hide();
			}
			
			Utils.axiosErrorAlert(error);
			
		});
		
		Logger.groupEnd(logAllow);
		
	};
	
	componentDidMount() {
		this.loadCourses();
	}
	
	render() {
		
		let student = this.props.student;
		
		let progress = this.state.progress;
		
		let courses = this.state.courses;
		
		let backBtn = this.props.backBtnOnClick ? (
			<BackBtn
				onClick={(event) => {
					this.props.backBtnOnClick(event);
				}}
				title={i18next.t("Back to the students list")}
			/>
		) : null;
		
		return (
			
			<div className={'StudentView student-view content-root row'}>
				
				<section className={`progress-col col-xl-8 col-lg-7 col-md-6`}>
					
					<div className={`page-title h2`}>
						{backBtn} <span className={'student-name'}>{student.getDisplayName()}</span> <small className={'text-muted'}>#{student.id}</small>
					</div>
					
					<div className="list progress-list">
						
						{(courses.length > 0) ? (
							
							<>
								
								{courses.map((course, courseIndex) => {
									
									let courseUnfolded = this.state.unfoldedCoursesIds.indexOf(course.id) >= 0;
									// let courseUnfolded = 1;
									
									let lessons = course.lessons;
									
									let courseDebugInfo = [
										'#' + course.id,
										'progress: ' + course.progress,
									].join(' | ');
									
									return (
										
										<div
											key={'course-' + course.id}
											className={[
												'list',
												'course',
												'foldable',
												courseUnfolded ? 'unfolded' : 'folded',
											].join(' ')}
										>
											
											<ListItem
												active={true}
												className={[
													'course-name',
													'head',
												].join(' ')}
												progress={course.progress}
												// title={course.progress + '%'}
												onClick={(event) => {
													
													let unfoldedCoursesIds = this.state.unfoldedCoursesIds;
													
													if (lessons.length < 1) {
														this.loadLessons(course.id, courseIndex);
													}
													
													Utils.arrayToggleElement(unfoldedCoursesIds, course.id);
													
													this.setState((prevState) => {
														return {
															unfoldedCoursesIds: unfoldedCoursesIds,
														}
													});
													
												}}
											>
												
												{courseIndex + 1}.&nbsp;{course.name} <small className={'text-muted progress-value'}> / {course.progress}%</small>
												
												{this.props.user.can('debugInfo') && (
													<div className={'debug-info'}>
														{courseDebugInfo}
													</div>
												)}
											
											</ListItem>
											
											{courseUnfolded && (
												
												<div
													className={[
														'list',
														'lessons-list',
													].join(' ')}
												>
													{(lessons.length > 0) ? (
														<>
															{lessons.map((lesson, lessonIndex) => {
																
																let lessonUnfolded = this.state.unfoldedLessonsIds.indexOf(lesson.id) >= 0;
																// let lessonUnfolded = 1;
																
																let lessonDebugInfo = [
																	'#' + lesson.id,
																	'progress: ' + lesson.progress,
																].join(' | ');
																
																let themes = lesson.themes;
																
																return (
																	
																	<div
																		className={[
																			'list',
																			'lesson',
																			'foldable',
																			lessonUnfolded ? 'unfolded' : 'folded',
																		].join(' ')}
																	>
																		
																		<ListItem
																			progress={lesson.progress}
																			// title={lesson.progress + '%'}
																			active={1}
																			className={'head'}
																			onClick={(event) => {
																				
																				let unfoldedLessonsIds = this.state.unfoldedLessonsIds;
																				
																				// folding without loading
																				if (themes.length < 1) {
																					this.loadThemes(lesson.id, lessonIndex, courseIndex);
																				}
																				
																				Utils.arrayToggleElement(unfoldedLessonsIds, lesson.id);
																				
																				this.setState((prevState) => {
																					return {
																						unfoldedLessonsIds: unfoldedLessonsIds,
																					}
																				});
																				
																			}}
																		>
																			
																			<div className="content">
																				
																				{lessonIndex + 1}. {lesson.name}
																				
																				&nbsp;<small className={'text-muted progress-value'}>/ {lesson.progress}%</small>
																				
																				{/*
																				{this.props.user.can('debugInfo') && (
																					<div className={'debug-info'}>
																						{lessonDebugInfo}
																					</div>
																				)}
																				*/}
																			
																			</div>
																		
																		</ListItem>
																		
																		{lessonUnfolded && (
																			
																			<div
																				className={[
																					'list',
																					'themes-list',
																				].join(' ')}
																			>
																				
																				{(themes.length > 0) ? (
																					<>
																						{themes.map((theme, themeIndex) => {
																							
																							let themeUnfolded = this.state.unfoldedThemesIds.includes(theme.id);
																							// let themeUnfolded = 1;
																							
																							// let foldable = theme.materials.length > 0;
																							// let foldable = theme.materials.length > 1;
																							let foldable = 1;
																							// let foldable = 0;
																							
																							let themeMaterials = theme.materials;
																							
																							let themeDebugInfo = [
																								'#' + theme.id,
																								'progress: ' + theme.progress,
																							].join(' | ');
																							
																							return foldable ? (
																								
																								<div
																									className={[
																										'list',
																										'theme',
																										foldable ? 'foldable' : '',
																										foldable ? (themeUnfolded ? 'unfolded' : 'folded') : '',
																									].join(' ')}
																								>
																									<ListItem
																										className={[
																											'themes-list-item',
																											'theme-preview',
																											'list-item',
																											'head',
																										].join(' ')}
																										progress={theme.progress}
																										active={1}
																										// title={theme.progress + '%'}
																										onClick={(event) => {
																											
																											let unfoldedThemesIds = this.state.unfoldedThemesIds;
																											
																											// loading only if needed
																											// if (themeMaterials.length < 1 && !themeUnfolded) {
																											if (!themeUnfolded) {
																												this.loadMaterials(theme.id, themeIndex, lessonIndex, courseIndex);
																											}
																											
																											Utils.arrayToggleElement(unfoldedThemesIds, theme.id);
																											
																											this.setState((prevState) => {
																												return {
																													unfoldedThemesIds: unfoldedThemesIds,
																												}
																											});
																											
																										}}
																									>
																										
																										{themeIndex + 1}. {theme.name}
																										&nbsp;<small className={'text-muted progress-value'}>/ {theme.progress}%</small>
																										
																										{/*{this.props.user.can('debugInfo') && (
																											<div className={'debug-info'}>
																												{themeDebugInfo}
																											</div>
																										)}*/}
																										
																									</ListItem>
																									
																									{themeUnfolded && (
																										
																										<div
																											className={[
																												'list',
																												'materials-list',
																												'progress-list',
																											].join(' ')}
																										>
																											
																											{(themeMaterials.length > 0) ? (
																												<>
																													{themeMaterials.map((material, materialIndex) => {
																														
																														let materialDebugInfo = [
																															'#' + material.id,
																															'progress: ' + material.progress,
																														].join(' | ');
																														
																														let detailsUrl = '/material-' + material.id + '-by-user-' + this.props.student.id;
																														
																														return (
																															<ListItem
																																className={[
																																	'material-preview',
																																	'materials-list-item',
																																	'progress-list-item',
																																].join(' ')}
																																progress={material.progress}
																																active={1}
																																onClick={() => {
																																	/*this.setState((prevState) => {
																																		return {
																																			currentCourse: course,
																																			currentLesson: lesson,
																																			currentTheme: theme,
																																			currentMaterial: material,
																																			questionsModalIsOpen: true,
																																		}
																																	});*/
																																}}
																																// title={material.progress + '%'}
																															>
																																
																																<div className="container">
																																
																																	<div className="row">
																																		
																																		<div className="col">
																																			
																																			{(true) ? (
																																				<Link
																																					to={detailsUrl}
																																					className={'material-name details-link'}
																																					target={'_blank'}
																																					title={i18next.t("Details on a separate page")}
																																				>{materialIndex + 1}. {material.name} <small className={'text-muted'}>#{material.id}</small> <Icon.ArrowUpRightCircleFill/></Link>
																																			) : (
																																				<>{materialIndex + 1}. {material.name} <small className={'text-muted'}>#{material.id}</small></>
																																			)}
																																			
																																			<div className="progress-value material-progress-value text-muted">
																																				
																																				{(material.progress_points) ? (
																																					
																																					<>
																																						
																																						<div className="max-points">
																																							{i18next.t("Max points")}: <b>{Utils.round(material.progress_points.max, 2)}</b>
																																						</div>
																																						
																																						<div className="student-points">
																																							{i18next.t("Student points")}: <b>{Utils.round(material.progress_points.user, 2)}</b>
																																						</div>
																																						
																																						<div className="progress-value">
																																							{i18next.t("Progress")}: <b>{material.progress}%</b>
																																						</div>
																																						
																																					</>
																																					
																																				) : (
																																					
																																					<>???</>
																																					
																																				)}
																																				
																																			</div>
																																			
																																			{/*{this.props.user.can('debugInfo') && (
																																				<div className={'debug-info'}>
																																					{materialDebugInfo}
																																				</div>
																																			)}*/}
																																		
																																		</div>
																																		
																																		<div className="col-auto controls-col align-self-center">
																																			<div className="controls material-controls">
																																				<button
																																					type={'button'}
																																					className={[
																																						'unlock-btn',
																																						'reset-btn',
																																						'my-btn',
																																						'my-btn-sm',
																																						'my-btn-danger',
																																					].join(' ')}
																																					onClick={(event) => {
																																						this.unlockExam(material, materialIndex, themeIndex, lessonIndex, courseIndex);
																																					}}
																																				>{i18next.t("Reset")}</button>
																																			</div>
																																		</div>
																																	
																																	</div>
																																
																																</div>
																																
																															</ListItem>
																														);
																													})}
																												</>
																											) : (
																												<>{i18next.t("Materials not found")}</>
																											)}
																											
																										</div>
																										
																									)}
																								
																								</div>
																								
																							) : (
																								
																								<ListItem
																									className={[
																										'list-item',
																									].join(' ')}
																									progress={theme.progress}
																									active={1}
																									title={theme.progress + '%'}
																								>
																									<div
																										className="content">
																										{themeIndex + 1}. {theme.name}
																										&nbsp;<small
																										className={'text-muted progress-value'}>/ {theme.progress}%</small>
																									</div>
																								</ListItem>
																								
																							);
																							
																						})}
																					</>
																				) : (
																					<>
																						{i18next.t("Themes not found")}
																					</>
																				)}
																			
																			</div>
																		
																		)}
																	
																	</div>
																
																);
																
															})}
														</>
													) : (
														<>
															{i18next.t("Lessons not found")}
														</>
													)}
												</div>
											
											)}
										
										</div>
									
									);
									
								})}
							
							</>
						
						) : (
							
							<>{i18next.t("Courses not found")}</>
						
						)}
					
					</div>
				
				</section>
				
				<section className="student-info-col col-xl-4 col-lg-5 col-md-6">
					
					<div className="h2">{i18next.t("Student")}</div>
					
					<div className="list">
						
						<div className="student-info">
							
							<div className="row">
								
								<div className="col-6 img-col align-self-center">
									<img src={AvatarPlugImg} alt="avatar" className={'avatar'}/>
								</div>
								
								<div className="col-6 text-col align-self-center">
									
									<div className="in">
										
										<div className="item full-name" title={'#' + student.id}>
											<b>{student.getDisplayName()}</b> <small className={'text-muted'}>#{student.id}</small>
										</div>
										
										<div className="item groups mt-3">
											
											{i18next.t("Groups")}:
											
											{(student.groups.length > 0) ? (
												<ol>
													{student.groups.map((group) => {
														return (
															<li>{group.name} <small className={'text-muted'}>#{group.id}</small></li>
														);
													})}
												</ol>
											) : (
												<ul>
													<li>{i18next.t("Not found")}</li>
												</ul>
											)}
										
										</div>
										
										<div className="item email-box mt-3 ellipsis-box">
											<a
												href={'mailto:' + student.email}
												className={'text-link'}
												title={student.email}
											>{student.email}</a>
										</div>
										
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>
					
				</section>
				
				<div className="modals">
					
					{(this.state.currentMaterial) && (
						
						<Modal
							className={'questions-modal'}
							show={this.state.questionsModalIsOpen}
							onHide={() => {
								this.setState((prevState) => {
									return {
										questionsModalIsOpen: false,
									}
								});
							}}
							size={'lg'}
							backdrop={'static'}
							keyboard={true}
						>
							
							<Modal.Header closeButton>
								<Modal.Title>
									{this.state.currentMaterial.name}
									<div className={'course-name small text-muted'}>{i18next.t("Курс")}: {this.state.currentCourse.name}</div>
									<div className={'lesson-name small text-muted'}>{i18next.t("Урок")}: {this.state.currentLesson.name}</div>
									<div className={'theme-name small text-muted'}>{i18next.t("Тема")}: {this.state.currentTheme.name}</div>
								</Modal.Title>
							</Modal.Header>
							
							<Modal.Body>
							
							</Modal.Body>
						
						</Modal>
						
					)}
					
				</div>
				
			</div>
			
		);
	}
	
}

StudentView.propTypes = {
	user: PropTypes.instanceOf(User).isRequired,
	student: PropTypes.instanceOf(User).isRequired,
	backBtnOnClick: PropTypes.func,
	preloader: PropTypes.object,
};

StudentView.defaultProps = {
	progress: [],
};