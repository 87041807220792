import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import i18next from "i18next";
import Logger from "../../helpers/Logger";
import Material from "../../models/Material";
import CourseTree from "./CourseTree";
import Theme from "../../models/Theme";
import ListItem from "./ListItem";

const MaterialCopyModal = ({material, course, themes = [], isOpen, onHide = () => {}, onCopy = () => {}}) => {
	
	const logName = 'MaterialCopyModal'
	const logAllow = 1
	const logCollapsed = 0
	
	Logger.groupStart(logName, logAllow, logCollapsed)
	
	const [targetTheme, setTargetTheme] = useState(null)
	
	Logger.log(isOpen, 'isOpen', logAllow)
	Logger.log(material, 'material', logAllow)
	Logger.log(themes, 'themes', logAllow)
	Logger.log(targetTheme, 'targetTheme', logAllow)
	
	Logger.groupEnd(logAllow)
	
	return material ? (
		
		<Modal
			className={'MaterialCopyModal'}
			show={Boolean(isOpen)}
			onHide={() => {
				setTargetTheme(null)
				onHide()
			}}
			size={'lg'}
			// backdrop={'static'}
			// keyboard={false}
		>
			
			<Modal.Header closeButton>
				<Modal.Title>{i18next.t(`Copy material`)}</Modal.Title>
			</Modal.Header>
			
			<Modal.Body>
				
				<p className={`text-center`}>
					{i18next.t(`Choose target theme`)}
				</p>
				
				<CourseTree
					course={course}
					folded={false}
					useMaterials={false}
					onThemeFoldToggle={(theme) => {
						
						const logName = 'MaterialCopyModal.CourseTree.onThemeFoldToggle'
						const logAllow = 1
						const logCollapsed = 0
						
						Logger.groupStart(logName, logAllow, logCollapsed)
						
						Logger.log(theme, 'theme', logAllow)
						Logger.log(targetTheme, 'targetTheme', logAllow)
						
						let newTargetTheme = null
						
						if (targetTheme?.id != theme.id) {
							newTargetTheme = theme
						}
						
						setTargetTheme(newTargetTheme)
						
						Logger.groupEnd(logAllow)
						
					}}
					themeExtraClassNameMaker={(theme) => {
						
						let out = []
						
						if (targetTheme?.id == theme.id) {
							out.push('list-item-current')
							out.push('selected')
						}
						
						return out
						
					}}
				/>
				
				<div className={`controls text-center mt-4`}>
					
					<button
						type={'button'}
						className={`my-btn my-btn-success`}
						disabled={!targetTheme}
						onClick={(event) => {
							onCopy(material, targetTheme)
						}}
					>{i18next.t(`Copy`)}</button>
					
				</div>
				
			</Modal.Body>
			
		</Modal>
		
	) : null
	
}

MaterialCopyModal.propTypes = {
	material: PropTypes.instanceOf(Material).isRequired,
	themes: PropTypes.arrayOf(Theme),
}

export default MaterialCopyModal