import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "./ListItem";
import i18next from "i18next";
import Logger from "../../helpers/Logger";

import "./FoldableList.css";

export default class FoldableList extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			folded: props.folded,
		};
		
	}
	
	toggle = () => {
		this.setState((prevState) => {
			return {
				folded: !prevState.folded,
			}
		});
	};
	
	componentDidMount() {
		// todo ajax
	}
	
	render() {
		
		const logName = 'FoldableList.render';
		const logAllow = 0;
		const logCollapsed = 0;
		
		Logger.groupStart(logName, logAllow, logCollapsed);
		
		Logger.log(this.props.folded, 'this.props.folded', logAllow);
		
		Logger.groupEnd(logAllow);
		
		return (
			
			<div
				className={[
					'FoldableList',
					'list',
					'foldable',
					Boolean(this.state.folded) || Boolean(this.props.noContent) ? 'folded' : 'unfolded',
					Boolean(this.props.noContent) ? 'no-content' : 'has-content',
					...this.props.extraClassName,
				].join(' ')}
			>
				
				<ListItem
					active={Boolean(this.props.active)}
					className={[
						'head',
						Boolean(this.props.isCurrent) ? 'list-item-current' : '',
					].join(' ')}
					progress={this.props.progress}
					title={this.props.title}
					onClick={(event) => {
						
						this.toggle();
						
						if (this.props.onToggle) {
							this.props.onToggle();
						}
						
						if (this.state.folded) {
							if (this.props.onUnfold) {
								this.props.onUnfold();
							}
						} else {
							if (this.props.onFold) {
								this.props.onFold();
							}
						}
						
					}}
				>
					
					{(this.props.upName) && (
						<div className={`up-name text-small text-muted`}>
							{this.props.upName}
						</div>
					)}
					
					<div className={`name`}>
						{this.props.name}
					</div>
					
					{(this.props.debugText) && (
						<div className={`debug-text text-small text-muted`}>
							{this.props.debugText}
						</div>
					)}
				
				</ListItem>
				
				<div className="body">
					{this.props.children}
				</div>
			
			</div>
		
		);
		
	}
	
}

FoldableList.propTypes = {
	
	name: PropTypes.any,
	title: PropTypes.string,
	upName: PropTypes.any,
	subNuame: PropTypes.any,
	debugText: PropTypes.string,
	
	folded: PropTypes.bool,
	active: PropTypes.any,
	
	onToggle: PropTypes.func,
	onFold: PropTypes.func,
	onUnfold: PropTypes.func,
	
	progress: PropTypes.number,
	
	extraClassName: PropTypes.array,
	
	// hides arrow if true
	noContent: PropTypes.any,
	isCurrent: PropTypes.any,
	
};

FoldableList.defaultProps = {
	folded: true,
	active: true,
	extraClassName: [],
};