import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import {ProgressBar} from "react-bootstrap";

const PreloaderProgressBar = ({}) => {
	
	return (
		
		<ProgressBar
			className={`preloader-progress-bar`}
			animated
			now={100}
			label={i18next.t(`Loading`) + '...'}
		/>
	
	)
	
}

PreloaderProgressBar.propTypes = {}

export default PreloaderProgressBar