import i18next from "i18next";

export default class MaterialGroupBind {
	
	constructor(data = {}) {
		
		this.id = data.id;
		
		this.material_id = data.material_id;
		this.group_id = data.group_id;
		
		// store for batch binds creation
		this.grous_ids = []
		
		this.access_start = data.access_start;
		this.access_start_unixtime = data.access_start_unixtime;
		this.access_start_date = data.access_start_date;
		this.access_start_time = data.access_start_time;
		
		this.access_end = data.access_end;
		this.access_end_unixtime = data.access_end_unixtime;
		this.access_end_date = data.access_end_date;
		this.access_end_time = data.access_end_time;
		
		this.group_name = data.group_name;
		
	}
	
	static makeFromArray = (dataArray) => {
		return dataArray.map(dataItem => new MaterialGroupBind(dataItem));
	};
	
	getName = () => {
		return i18next.t("Access for group ") + (this.group_name || '#' + this.group_id)
	};
	
}